// If foundation uncomment this line
$(document).foundation();

// Callout
$.fn.callout = function(message, type) {
    return $(this).html('<div data-closable class="' + type + '">' + message + '</div>');
};

/**
 * Equalizer
 */
$.fn.equalizer = function() {

    // Reset height
    $(this).css('height', 'auto');

    // Lopp elements
    var elementHeights = $(this).map(function() {
        return $(this).outerHeight();
    }).get();

    // Get element with highest height
    var maxHeight = Math.max.apply(null, elementHeights);

    // Set height
    $(this).css('height', maxHeight);
};

// Form
$('form.contact').on('submit', function(event) {
    event.preventDefault();

    // Used varables
    var self = this;
    var response = $(this).find('.response');

    // Alert classes
    var success_class = "callout success";
    var error_class = "callout alert";
    var loading_class = "callout primary";
    var button = $(this).find('.button');

    // Add loader
    button.attr('data-loading', '');
    response.html('');

    // Ajax submit
    $.ajax({
        type: 'POST',
        dataType: 'json',
        url: ajax_url + 'contact',
        data: $(self).serialize(),

        // If success
        success: function(data){

            // Remove loader
            button.removeAttr('data-loading');

            // Response
            if (data.success) {

                // Set success
                response.callout(data.message, success_class);

                // Clear fields
                $(self).find('input, textarea').val('');
            } else {

                // Set error
                response.callout(data.message, error_class);
            };
        }

    });
});

// Report form
$('form.report').on('submit', function(event) {
    event.preventDefault();

    // Used varables
    var self = this;
    var response = $(this).find('.response');

    // Alert classes
    var success_class = "callout success";
    var error_class = "callout alert";
    var loading_class = "callout primary";
    var button = $(this).find('.button');

    // Add loader
    button.attr('data-loading', '');
    response.html('');

    // Ajax submit
    $.ajax({
        type: 'POST',
        dataType: 'json',
        url: ajax_url + 'report',
        data: $(self).serialize(),

        // If success
        success: function(data){

            // Remove loader
            button.removeAttr('data-loading');

            // Response
            if (data.success) {

                // Set success
                response.callout(data.message, success_class);

                // Clear fields
                $(self).find('input, textarea').val('');
            } else {

                // Set error
                response.callout(data.message, error_class);
            };
        }

    });
});

/**
 * Plain js map
 * usage: add div with id "gmap",
 * in attr data-address add addres
 * and setup height of map, thats all
 */

var routePath = '';
function googleMap() {
    var geocoder = new google.maps.Geocoder();

    var map = document.getElementById('gmap');

    if (map) {
        var coord = map.getAttribute('data-address');
    }

    if (coord) {
        geocoder.geocode({'address': coord}, function(results, status) {
            if (status == google.maps.GeocoderStatus.OK) {

                var lat = results[0].geometry.location.lat();
                var lng = results[0].geometry.location.lng();
                var latLng = new google.maps.LatLng(lat,lng);

                var mapOptions = {
                    center: latLng,
                    scrollwheel: false,
                    zoom: 13,
                    styles: [{"featureType":"water","elementType":"geometry.fill","stylers":[{"color":"#d3d3d3"}]},{"featureType":"transit","stylers":[{"color":"#808080"},{"visibility":"off"}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"visibility":"on"},{"color":"#b3b3b3"}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"road.local","elementType":"geometry.fill","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"weight":1.8}]},{"featureType":"road.local","elementType":"geometry.stroke","stylers":[{"color":"#d7d7d7"}]},{"featureType":"poi","elementType":"geometry.fill","stylers":[{"visibility":"on"},{"color":"#ebebeb"}]},{"featureType":"administrative","elementType":"geometry","stylers":[{"color":"#a7a7a7"}]},{"featureType":"road.arterial","elementType":"geometry.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"road.arterial","elementType":"geometry.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"landscape","elementType":"geometry.fill","stylers":[{"visibility":"on"},{"color":"#efefef"}]},{"featureType":"road","elementType":"labels.text.fill","stylers":[{"color":"#696969"}]},{"featureType":"administrative","elementType":"labels.text.fill","stylers":[{"visibility":"on"},{"color":"#737373"}]},{"featureType":"poi","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"poi","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"road.arterial","elementType":"geometry.stroke","stylers":[{"color":"#d6d6d6"}]},{"featureType":"road","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{},{"featureType":"poi","elementType":"geometry.fill","stylers":[{"color":"#dadada"}]}]
                };

                var map = new google.maps.Map(document.getElementById('gmap'), mapOptions);
                var marker = new google.maps.Marker({
                    position: latLng,
                    map: map,
                    icon: site_url + 'dist/img/marker.png'
                });

                var infowindow = new google.maps.InfoWindow({
                    content: "<div id='marker' data-address='"+ results[0].formatted_address +"'>"+ results[0].formatted_address +"</div>"
                });

                marker.addListener('click', function() {
                    console.log(routePath);

                    if (routePath != '') {
                        window.open(routePath, '_blank');
                    } else {
                        infowindow.open(map, marker);
                    }
                });

                function getLocation() {
                    if (navigator.geolocation) {
                        navigator.geolocation.getCurrentPosition(createAnchor);
                    }
                };

                function createAnchor(position) {
                    var location = [
                        position.coords.latitude + ' ' + position.coords.longitude,
                        results[0].formatted_address
                    ];

                    routePath = 'https://www.google.pl/maps/dir/'+ location[0] +'/'+ location[1];
                };

                getLocation();
            };
        });
    }
}

/**
 * Load & resize
 */
$(window).on('load resize orientationchange', function () {
    $('.image__item').equalizer();
    $('.block').equalizer();
});

/**
 * Tabs categories
*/
$('.image').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    asNavFor: '.thumb',
    nextArrow: '<div class="slick-arrow slick-arrow--next"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 477.175 477.175"><path d="M360.73 229.075l-225.1-225.1c-5.3-5.3-13.8-5.3-19.1 0s-5.3 13.8 0 19.1l215.5 215.5-215.5 215.5c-5.3 5.3-5.3 13.8 0 19.1 2.6 2.6 6.1 4 9.5 4 3.4 0 6.9-1.3 9.5-4l225.1-225.1c5.3-5.2 5.3-13.8.1-19z"/></svg></div>',
    prevArrow: '<div class="slick-arrow slick-arrow--prev"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 477.175 477.175"><path d="M360.73 229.075l-225.1-225.1c-5.3-5.3-13.8-5.3-19.1 0s-5.3 13.8 0 19.1l215.5 215.5-215.5 215.5c-5.3 5.3-5.3 13.8 0 19.1 2.6 2.6 6.1 4 9.5 4 3.4 0 6.9-1.3 9.5-4l225.1-225.1c5.3-5.2 5.3-13.8.1-19z"/></svg></div>',
});

$('.thumb').slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: '.image',
    dots: false,
    arrows: true,
    nextArrow: '<div class="slick-arrow slick-arrow--next"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 477.175 477.175"><path d="M360.73 229.075l-225.1-225.1c-5.3-5.3-13.8-5.3-19.1 0s-5.3 13.8 0 19.1l215.5 215.5-215.5 215.5c-5.3 5.3-5.3 13.8 0 19.1 2.6 2.6 6.1 4 9.5 4 3.4 0 6.9-1.3 9.5-4l225.1-225.1c5.3-5.2 5.3-13.8.1-19z"/></svg></div>',
    prevArrow: '<div class="slick-arrow slick-arrow--prev"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 477.175 477.175"><path d="M360.73 229.075l-225.1-225.1c-5.3-5.3-13.8-5.3-19.1 0s-5.3 13.8 0 19.1l215.5 215.5-215.5 215.5c-5.3 5.3-5.3 13.8 0 19.1 2.6 2.6 6.1 4 9.5 4 3.4 0 6.9-1.3 9.5-4l225.1-225.1c5.3-5.2 5.3-13.8.1-19z"/></svg></div>',
    centerMode: true,
    focusOnSelect: true,
    responsive: [
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
            }
        }
    ]
});

/**
 * Slider
 */
$('.slides__loop').slick({
    autoplay: true,
    autoplaySpeed: 3000,

    centerMode: true,
    infinite: true,
    slidesToShow: 1,

    nextArrow: '<div class="slick-arrow slick-arrow--next"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 477.175 477.175"><path d="M360.73 229.075l-225.1-225.1c-5.3-5.3-13.8-5.3-19.1 0s-5.3 13.8 0 19.1l215.5 215.5-215.5 215.5c-5.3 5.3-5.3 13.8 0 19.1 2.6 2.6 6.1 4 9.5 4 3.4 0 6.9-1.3 9.5-4l225.1-225.1c5.3-5.2 5.3-13.8.1-19z"/></svg></div>',
    prevArrow: '<div class="slick-arrow slick-arrow--prev"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 477.175 477.175"><path d="M360.73 229.075l-225.1-225.1c-5.3-5.3-13.8-5.3-19.1 0s-5.3 13.8 0 19.1l215.5 215.5-215.5 215.5c-5.3 5.3-5.3 13.8 0 19.1 2.6 2.6 6.1 4 9.5 4 3.4 0 6.9-1.3 9.5-4l225.1-225.1c5.3-5.2 5.3-13.8.1-19z"/></svg></div>',
});

/**
 * Show languages
 */
$(document).on('click', '.lang', function () {
    $(this).toggleClass('focus');
});

/**
 * Menu
 */
$(window).on('load resize', function () {
    var nav = $('.header__menu');
    var item = nav.find('li:first-child');
    var header = $('.header');
    var secondary = $('.slides__bottom ul');

    secondary.removeClass('mobile');
    nav.removeClass('mobile');
    nav.removeClass('mobile--visible');
    header.removeClass('header--mobile-nav');

    $('.burger').removeClass('open');

    if (nav.outerHeight() > item.outerHeight()) {
        secondary.addClass('mobile');
        nav.addClass('mobile');
        header.addClass('header--mobile-nav');
    }

    $('.cat-title').equalizer();
});

/**
 * Open
 */
$('.burger').on('click', function () {
    $(this).toggleClass('open');
    $('.mobile').toggleClass('mobile--visible');
});

/**
 * Slides menu
 */
$(window).on('load', function () {
    var item = $('.slides .slides__bottom li');
    item.width(100/item.length + '%');
});

/**
 * Lightbox
 */
lightbox.option({
    'resizeDuration': 200,
    'wrapAround': true
})

/**
 * Sticky load
 */
$(window).on('load', function () {
    var offset = $('.header').offset().top;

    /**
     * Sticky nav
     */
    $(window).on('scroll', function () {
        if ($(window).scrollTop() >= offset) {
            $('.header').addClass('stick');
        } else {
            $('.header').removeClass('stick');
        }
    });
});

$('article li').wrapInner('<span></span>');
